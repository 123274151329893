import React, { useMemo, useState, useEffect } from 'react';
import FinancialGraph from './FinancialGraph';
import SummaryTable from './SummaryTable';
import DetailedTable from './DetailedTable';
import Legend from './Legend';
import './App.css'; // Ensure you have the CSS file for animations
import logo from '../src/edenLogo.png'

const calculateMilestones = (profile, futureNetWorth, year, yearStart) => {
  const retirementTarget = profile.averageSpending * 25;
  const milestones = {
    EmergencyFund: (profile.averageSpending / 12) * 6,
    CoastFIRE: retirementTarget,
    BaristaFIRE: (profile.averageSpending * 0.5) * 20,
    LeanFIRE: (profile.averageSpending * 0.75) * 20,
    FIRE: profile.averageSpending * 25,
    FatFIRE: profile.averageSpending * 30,
  };

  const milestoneYears = {
    EmergencyFund: "Not Achieved",
    CoastFIRE: "Not Achieved",
    BaristaFIRE: "Not Achieved",
    LeanFIRE: "Not Achieved",
    FIRE: "Not Achieved",
    FatFIRE: "Not Achieved",
  };

  for (let milestone in milestones) {
    const amount = milestones[milestone];
    if (milestone === "CoastFIRE") {
      const futureValueAt67 = futureNetWorth * Math.pow(1 + (profile.investmentReturn - profile.inflationRate) / 100, 67 - (profile.age + year));
      if (futureValueAt67 >= retirementTarget && milestoneYears[milestone] === "Not Achieved") {
        milestoneYears[milestone] = yearStart + year;
      }
    } else {
      if (futureNetWorth >= amount && milestoneYears[milestone] === "Not Achieved") {
        milestoneYears[milestone] = yearStart + year;
      }
    }
  }

  return milestoneYears;
};

// Function to calculate net worth over time and milestones
const calculateProjections = (profiles, yearStart, yearLookahead) => {
  const years = Array.from({ length: yearLookahead }, (_, i) => yearStart + i);

  const results = profiles.map(profile => {
    let futureNetWorth = profile.currentNetWorth;
    let annualIncome = profile.salary;
    let investmentSavings = profile.investmentSavingRate;
    let postExpenseSavings = ((0.7 * annualIncome) - profile.averageSpending);
    let averageExpenses = profile.averageSpending;
    let fourPercent = profile.currentNetWorth * 0.04;

    const netWorthOverTime = [];
    const yearByYearData = [];
    const milestoneYears = {
      EmergencyFund: "Not Achieved",
      CoastFIRE: "Not Achieved",
      BaristaFIRE: "Not Achieved",
      LeanFIRE: "Not Achieved",
      FIRE: "Not Achieved",
      FatFIRE: "Not Achieved",
    };

    for (let year = 1; year <= yearLookahead; year++) {
      annualIncome *= 1 + (profile.wageGrowthRate - profile.inflationRate) / 100;
      averageExpenses *= 1 + (profile.inflationRate) / 100;
      annualIncome = Math.min(annualIncome, 1000000);
      futureNetWorth *= 1 + (profile.investmentReturn - profile.inflationRate) / 100;
      postExpenseSavings =  ((0.7 * annualIncome) - profile.averageSpending)
      futureNetWorth += postExpenseSavings + profile.investmentSavingRate;
      fourPercent = futureNetWorth * 0.04

      const milestones = calculateMilestones(profile, futureNetWorth, year, yearStart);

      Object.keys(milestones).forEach(milestone => {
        if (milestoneYears[milestone] === "Not Achieved") {
          milestoneYears[milestone] = milestones[milestone];
        }
      });

      netWorthOverTime.push(futureNetWorth);
      yearByYearData.push({
        year: yearStart + year,
        annualIncome: `$${annualIncome.toFixed(2).toLocaleString()}`,
        futureNetWorth: `$${futureNetWorth.toFixed(2).toLocaleString()}`,
        investmentSavings: `$${investmentSavings.toFixed(2).toLocaleString()}`,
        postExpenseSavings: `$${postExpenseSavings.toFixed(2).toLocaleString()}`,
        avgExepenses: `$${averageExpenses.toFixed(2).toLocaleString()}`,
        currentFourPercent: `$${fourPercent.toFixed(2).toLocaleString()}`,
      });
    }

    const formattedNetWorth = `$${futureNetWorth.toFixed(2).toLocaleString()}`;

    return { 
      ...profile, 
      netWorthOverTime, 
      yearByYearData, 
      formattedNetWorth, 
      milestoneYears 
    };
  });

  return { years, profiles: results };
};

function App() {
  const yearStart = 2024;
  const [yearLookahead, setYearLookahead] = useState(15); // State to track the number of years

  useEffect(() => {
    document.title = 'Luxon Levels';
  
    // Create a link element for the favicon
    const favicon = document.createElement('link');
    favicon.rel = 'icon';
    favicon.href = logo; // Use the imported logo as the favicon
    favicon.type = 'image/png'; // Specify the image type
  
    // Append the favicon link element to the document head
    document.head.appendChild(favicon);
  
    // Cleanup function to remove the favicon when the component is unmounted
    return () => {
      document.head.removeChild(favicon);
    };
  }, []);

  const profiles  = [
    { name: 'Microsoft', salary: 190_000, currentNetWorth: 250000, investmentSavingRate: 31000, averageSpending: 90000, yearsLeft: 100, inflationRate: 2, wageGrowthRate: 5, investmentReturn: 8, color: '#8884d8', age: 26 },
    { name: 'FAANG Couple', salary: 290_000, currentNetWorth: 265000, investmentSavingRate: 31000, averageSpending: 90000, yearsLeft: 100, inflationRate: 2, wageGrowthRate: 5, investmentReturn: 8, color: '#82ca9d', age: 26 },
    { name: 'Google', salary: 280_000, currentNetWorth: 250000, investmentSavingRate: 31000, averageSpending: 90000, yearsLeft: 100, inflationRate: 2, wageGrowthRate: 5, investmentReturn: 8, color: '#ffc658', age: 26 },
    { name: 'MSFT to Dell', salary: 130_000, currentNetWorth: 250000, investmentSavingRate: 31000, averageSpending: 90000, yearsLeft: 5, inflationRate: 2, wageGrowthRate: 5, investmentReturn: 8, color: '#ff7300', age: 26 },
    { name: 'Hopper', salary: 325_000, currentNetWorth: 250000, investmentSavingRate: 31000, averageSpending: 90000, yearsLeft: 100, inflationRate: 2, wageGrowthRate: 10, investmentReturn: 8, color: '#8b0000', age: 26 },
  ];

  const milestones = [
    { label: 'Emergency Fund', value: 35000 },
    { label: 'CoastFIRE', value: 250000 },
    { label: 'BaristaFIRE', value: 1250000 },
    { label: 'LeanFIRE', value: 2000000 },
    { label: 'FIRE', value: 2500000 },
    { label: 'FatFIRE', value: 3000000 }
  ];

  const [selectedProfiles, setSelectedProfiles] = useState(profiles.map(profile => profile.name));

  const handleProfileSelection = (name) => {
    setSelectedProfiles(prevSelected => 
      prevSelected.includes(name) ? prevSelected.filter(profile => profile !== name) : [...prevSelected, name]
    );
  };

  const filteredProfiles = profiles.filter(profile => selectedProfiles.includes(profile.name));
  const { years, profiles: profileData } = useMemo(() => calculateProjections(filteredProfiles, yearStart, yearLookahead), [profiles]);

return (
  <div>
    <h1>Financial Projections Dashboard</h1>
    <div className="year-input">
        <label htmlFor="yearLookahead">Number of Years:</label>
        <input 
          type="number" 
          id="yearLookahead" 
          value={yearLookahead} 
          onChange={(e) => setYearLookahead(parseInt(e.target.value, 10))} 
          min="1" 
          max="100" 
        />
      </div>
    <FinancialGraph data={{ years, profiles: profileData }} milestones={milestones} selectedProfiles={selectedProfiles} onToggle={handleProfileSelection} />
    <Legend profiles={profiles} selectedProfiles={selectedProfiles} onToggle={handleProfileSelection} />
    <SummaryTable profiles={profileData} />
    <DetailedTable profiles={profileData} />
  </div>
);
}



export default App;

import React, { useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MenuItem, Select, FormControl, InputLabel, Tooltip } from '@mui/material';
import { styled } from '@mui/system';

const StyledFormControl = styled(FormControl)({
  marginBottom: '8px',
  minWidth: 120,
  backgroundColor: '#2c2c2c',
  color: '#fff',
  '& .MuiInputLabel-root': {
    color: '#fff',
  },
  '& .MuiSelect-root': {
    color: '#fff',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#fff',
  },
  '& .MuiSvgIcon-root': {
    color: '#fff',
  },
});

const StyledMenuItem = styled(MenuItem)({
  backgroundColor: '#2c2c2c',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#444',
  },
});

// Dark theme for the tables
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
          backgroundColor: '#2c2c2c',
        },
        cell: {
          color: '#fff',
        },
        columnHeaders: {
          backgroundColor: '#333',
          color: '#fff',
          fontWeight: 'bold',
        },
        row: {
          '&:hover': {
            backgroundColor: '#444',
          },
        },
      },
    },
  },
});

// Function to format numbers as currency
const formatCurrency = (value) => {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
};

// Custom comparator for sorting currency values
const currencyComparator = (v1, v2) => {
  const num1 = parseFloat(v1.replace(/[^0-9.-]+/g,""));
  const num2 = parseFloat(v2.replace(/[^0-9.-]+/g,""));
  return num1 - num2;
};

const DetailedTable = ({ profiles }) => {
  const [selectedYear, setSelectedYear] = useState('');

  // Extract all unique years from profiles
  const years = [...new Set(profiles.flatMap(profile => profile.yearByYearData.map(yearData => yearData.year)))];

  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const columns = [
    { field: 'Profile', headerName: 'Profile', width: 200 },
    { 
      field: 'Annual Income', 
      headerName: 'Annual Income', 
      description: "The total compensation for the role", 
      width: 150,
      sortComparator: currencyComparator,
    },
    { 
      field: 'Average Expenses', 
      headerName: 'Average Expenses', 
      description: "$ Average expenses that are deducted from TC", 
      width: 150,
      sortComparator: currencyComparator,
    },
    { 
      field: 'Post Expense Savings', 
      headerName: 'Post Expense Savings', 
      description: "$ Saved after deducting expenses from TC", 
      width: 150,
      sortComparator: currencyComparator,
    },
    { 
      field: 'Investment Savings', 
      headerName: 'Investment Savings', 
      description: "How much being saved for investments (401k + Roth + etc)", 
      width: 150,
      sortComparator: currencyComparator,
    },
    { 
      field: 'Future Net Worth', 
      headerName: 'Future Net Worth', 
      description: "The Net Worth at the selected year", 
      width: 150,
      sortComparator: currencyComparator,
    },
    { 
      field: 'Future 4%', 
      headerName: 'Future 4%', 
      description: "The value of the 4% rule on the current net worth", 
      width: 150,
      sortComparator: currencyComparator,
    },
  ];

  const rows = profiles.map((profile, index) => {
    const yearData = profile.yearByYearData.find(data => data.year === selectedYear);
    return {
      id: index + 1,
      Profile: profile.name,
      'Annual Income': yearData ? formatCurrency(parseFloat(yearData.annualIncome.replace('$', '').replace(',', ''))) : 'N/A',
      'Average Expenses': yearData ? formatCurrency(parseFloat(yearData.avgExepenses.replace('$', '').replace(',', ''))) : 'N/A',
      'Post Expense Savings': yearData ? formatCurrency(parseFloat(yearData.postExpenseSavings.replace('$', '').replace(',', ''))) : 'N/A',
      'Investment Savings': yearData ? formatCurrency(parseFloat(yearData.investmentSavings.replace('$', '').replace(',', ''))) : 'N/A',
      'Future Net Worth': yearData ? formatCurrency(parseFloat(yearData.futureNetWorth.replace('$', '').replace(',', ''))) : 'N/A',
      'Future 4%': yearData ? formatCurrency(parseFloat(yearData.currentFourPercent.replace('$', '').replace(',', ''))) : 'N/A',
    };
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <div style={{ height: 400, width: '100%' }}>
        <StyledFormControl variant="outlined">
          <InputLabel id="year-select-label">Year</InputLabel>
          <Select
            labelId="year-select-label"
            id="year-select"
            value={selectedYear}
            onChange={handleYearChange}
            label="Year"
          >
            {years.map((year) => (
              <StyledMenuItem key={year} value={year}>
                {year}
              </StyledMenuItem>
            ))}
          </Select>
        </StyledFormControl>
        <DataGrid 
          rows={rows} 
          columns={columns} 
          pageSize={5} 
          rowsPerPageOptions={[5, 10, 20]} 
          checkboxSelection 
          disableSelectionOnClick 
          pagination
        />
      </div>
    </ThemeProvider>
  );
};

export default DetailedTable;

import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// Dark theme for the tables
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
  components: {
    MuiDataGrid: {
      styleOverrides: {
        root: {
          border: 'none',
          backgroundColor: '#2c2c2c',
        },
        cell: {
          color: '#fff',
        },
        columnHeaders: {
          backgroundColor: '#333',
          color: '#fff',
          fontWeight: 'bold',
        },
        row: {
          '&:hover': {
            backgroundColor: '#444',
          },
        },
      },
    },
  },
});

const SummaryTable = ({ profiles }) => {
  // Define the columns for the DataGrid
  const columns = [
    { field: 'id', headerName: 'ID', width: 70, description: "The ID of the row"},
    { field: 'Profile', headerName: 'Profile', width: 150, description: "The company or situation" },
    { field: 'FutureNetWorth', headerName: 'Future Net Worth', width: 200, description: "Net worth after x years" },
    { field: 'InvestmentSavingsRate', headerName: 'Investment Savings Rate', width: 250, description: "401k savings per year" },
    { field: 'EmergencyFund', headerName: 'Emergency Fund', width: 150, description: "Year that an emergency fund is reached" },
    { field: 'CoastFIRE', headerName: 'CoastFIRE', width: 150, description: "The year when retirement savings are sufficient to grow without additional contributions" },
    { field: 'BaristaFIRE', headerName: 'BaristaFIRE', width: 150, description: "The year when semi-retirement is possible with part-time work" },
    { field: 'LeanFIRE', headerName: 'LeanFIRE', width: 150, description: "The year when a minimalist retirement is financially feasible" },
    { field: 'FIRE', headerName: 'FIRE', width: 150, description: "The year when full financial independence and retirement are achievable" },
    { field: 'FatFIRE', headerName: 'FatFIRE', width: 150, description: "The year when a more comfortable and affluent retirement is possible" },
  ];

  // Process profiles into rows for the DataGrid
  const rows = profiles.map((profile, index) => ({
    id: index + 1,
    Profile: profile.name,
    FutureNetWorth: `$${profile.netWorthOverTime[profile.netWorthOverTime.length - 1].toLocaleString()}`, // Assuming last value as future net worth
    InvestmentSavingsRate: `$${profile.investmentSavingRate.toLocaleString()}`,
    EmergencyFund: profile.milestoneYears.EmergencyFund || 'N/A',
    CoastFIRE: profile.milestoneYears.CoastFIRE || 'N/A',
    BaristaFIRE: profile.milestoneYears.BaristaFIRE || 'N/A',
    LeanFIRE: profile.milestoneYears.LeanFIRE || 'N/A',
    FIRE: profile.milestoneYears.FIRE || 'N/A',
    FatFIRE: profile.milestoneYears.FatFIRE || 'N/A',
  }));

  return (
    <ThemeProvider theme={darkTheme}>
      <div style={{ height: 400, width: '100%' }}>
      <DataGrid 
        rows={rows} 
        columns={columns} 
        pageSize={5} 
        rowsPerPageOptions={[5, 10, 20]} 
        checkboxSelection 
        disableSelectionOnClick 
      />
    </div>
    </ThemeProvider>
  );
};

export default SummaryTable;

import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
  ReferenceLine
} from 'recharts';

const FinancialGraph = ({ data, milestones, selectedProfiles, onToggle }) => {
  const chartData = data.years.map((year, index) => {
    const yearData = { year };
    data.profiles.forEach(profile => {
      yearData[profile.name] = profile.netWorthOverTime[index];
    });
    return yearData;
  });

  // Assign colors based on profile names
  const profileColors = {
    "Microsoft": '#8884d8',
    "FAANG Couple": '#82ca9d',
    "Google": '#ffc658',
    "MSFT to Dell": '#ff7300',
    "Hopper": '#8b0000',
    // Add more profiles and colors as needed
  };

  const CustomLegend = ({ profiles, selectedProfiles, onToggle }) => (
    <Legend profiles={profiles} selectedProfiles={selectedProfiles} onToggle={onToggle} />
  );

  // Calculate the Y-axis range and ticks
  const yAxisTicks = chartData
    .map(d => Object.values(d))
    .flat()
    .filter(v => typeof v === 'number')
    .map(v => Math.round(v / 100000) * 100000);

  const minTick = Math.min(...yAxisTicks);
  const maxTick = Math.max(...yAxisTicks);
  const midPoint = (maxTick - minTick) / 2 + minTick;
  const tickSpacing = (maxTick - midPoint) / 2;
  const ticks = [midPoint, midPoint + tickSpacing, maxTick]; // Start tick 50% up the graph

  // Filter out the "Emergency Fund" milestone
  const filteredMilestones = milestones.filter(milestone => milestone.label !== 'Emergency Fund');

  return (
    <ResponsiveContainer width="97%" height={750}>
      <LineChart
        data={chartData}
        margin={{ top: 20, right: 30, left: 50, bottom: 20 }} 
        style={{ backgroundColor: '#1c1c1c', borderRadius: '10px', padding: '20px' }}
      >
        <CartesianGrid strokeDasharray="3 3" stroke="#333" />
        <XAxis dataKey="year" stroke="#ccc" />
        <YAxis 
          tickFormatter={(value) => `$${value.toLocaleString()}`} 
          stroke="#ccc" 
          ticks={ticks}  // Custom ticks, starting 50% up the graph
          domain={[minTick, maxTick]}  // Ensure the domain covers the full range
        />
        <Tooltip
          formatter={(value) => `$${value.toLocaleString()}`}
          contentStyle={{ backgroundColor: '#333', borderRadius: '5px', border: 'none' }}
          itemStyle={{ color: '#fff' }}
        />
        <Legend content={<CustomLegend profiles={data.profiles} selectedProfiles={selectedProfiles} onToggle={onToggle} />} />

        {data.profiles.map((profile) => (
          <Line
            key={profile.name}
            type="monotone"
            dataKey={profile.name}
            stroke={profileColors[profile.name] || '#000000'} // Default to black if no color is specified
            strokeWidth={3}
            dot={{ r: 4 }}
            activeDot={{ r: 8 }}
            animationDuration={500}
          />
        ))}

        {/* Render milestone lines with labels, excluding "Emergency Fund" */}
        {filteredMilestones.map((milestone, index) => {
          const roundedCost = Math.round(milestone.value / 100000) * 100000;
          return (
            <ReferenceLine
              key={index}
              y={milestone.value}
              label={({ viewBox }) => (
                <g>
                  <text
                    x={viewBox.x - 10}  // Move the label to the left, outside the graph
                    y={viewBox.y - 10}
                    fill="#ffffff"
                    fontSize={13}
                    fontWeight="bold"
                    textAnchor="end"  // Align text to the end (right)
                  >
                    {milestone.label}
                  </text>
                  <text
                    x={viewBox.x - 10}  // Move the rounded cost to the left, outside the graph
                    y={viewBox.y + 5}
                    fill="#ffffff"
                    fontSize={10}
                    textAnchor="end"  // Align text to the end (right)
                  >
                    {`$${roundedCost.toLocaleString()}`}
                  </text>
                </g>
              )}
              stroke="grey"
              strokeDasharray="3 3"
            />
          );
        })}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default FinancialGraph;

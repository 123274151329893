import React from 'react';
import './Legend.css'; // Ensure this CSS file handles the styling for the legend

const Legend = ({ profiles, selectedProfiles, onToggle }) => {
  return (
    <div className="legend-container">
      {profiles.map((profile) => (
        <div key={profile.name} className="legend-item">
          <input
            type="checkbox"
            checked={selectedProfiles.includes(profile.name)}
            onChange={() => onToggle(profile.name)}
            style={{ marginRight: '8px' }}
          />
          <span
            className="legend-color"
            style={{ backgroundColor: profile.color }}
          ></span>
          {profile.name}
        </div>
      ))}
    </div>
  );
};

export default Legend;
